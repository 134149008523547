
import { Link } from "react-router";
import img from "../../../../assets/robo-consult.JPG"
import './contact-section.css';

export function ContactSection(){
    return(
        <>
        <div className='contact-form__main-container'>
            <div className='contact-form__left-container'>
                <img src={img} alt="Robot consultant" />
            </div>
            <div className='contact-form__right-container'>
                <div className="contact-form__support-button-container">
                    <div className="contact-form__support-element">
                        <h4>Потрібна підтримка?</h4>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta, quae.</p>
                        <Link to="/">Потрібна</Link>
                    </div>
                    <div className="contact-form__support-element">
                        <h4>Хочеш стати волонтером?</h4>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta, quae.</p>
                        <Link to="/">Хочу</Link>
                    </div>
                </div>
                <h3 className='contact-form__title'>Форма для зв'язку</h3>
                <form action="" className='contact-form__form'>
                    <div className='contact-form__input-container'>
                        <label htmlFor="name">Ім'я:</label>
                        <input type="text" name="name" id="name" placeholder="Анна"/>
                    </div>
                    <div className='contact-form__input-container'>
                        <label htmlFor="email">Email:</label>
                        <input type="email" name="email" id="email" placeholder="example@email.com" />
                    </div>
                    <div className='contact-form__input-container'>
                        <label htmlFor="message">Повідомлення:</label>
                        <textarea name="message" id="message" cols="30" rows={3} placeholder="Місце для вводу повідомлення"></textarea>
                    </div>
                    <button type='submit'>Надіслати</button>
                </form>
            </div>
        </div>
        </>
    );
}