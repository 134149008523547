import { Link } from "react-router-dom";
import img from "../../assets/heart-removebg-preview.png"
import "./header.css"

export function Header(){
    return(
        <header>
            <div className="logo__container"><img src="" alt="logo" /></div>
            <nav>
                <Link to={'/'}>Home</Link>
                <Link to={'/'}>About</Link>
                <Link to={'/'}>What we do</Link>
                <Link to={'/'}>Contact</Link>
            </nav>
            <Link to={"/"} className="donate__container">
                <img src={img} alt="heart" style={{width: "auto"}}/>
                <h3>Підтримати<br/>проєкт</h3>
            </Link>
        </header>
    );
}