

import { Link } from "react-router";
import './big-nav-button.css'

export function BigNavButtonLink(props){

    const data = props.data;


    return(
        <Link to={`${data["button-url"]}`} className="nav-links__big-button-link">
            <h4 >
                {data["button-title"]}
            </h4>
            <p>{data["button-text"]}</p>
        </Link>
    );
}