import { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
// import uuid from 'react-uuid';
import { SocialItem } from './socialItem';
import { SOCIAL_PAGES } from './social';
// import { SERVICES, COMPANY } from './footerNavItems';
import { LinkWithBackgroundComponent } from '../links/linkWithBackgroundComponent';
// import { useLang } from '../../hooks/useLang';
// import customFontWoff from '../../fonts/fixedsys-ligatures.ttf';
import './footer.css'

export function Footer(){

    // const {currentLanguage} = useLang();
    const [footerText, setFooterText] = useState([
                'Stay up-to-date on new AI technologies', 
                'Subscribe to our telegram chanel to learn about the latest developments in artificial intelligence.',
                'News chanel',
                'All rights reserved &copy; 2023-2024 TENETIX',
                'Yaroslaviv Val, 9',
                'Kyiv, Ukraine']);

    // useEffect(() => {
    //     if(currentLanguage === 'EN'){
    //         setFooterText([
    //             'Stay up-to-date on new AI technologies', 
    //             'Subscribe to our telegram chanel to learn about the latest developments in artificial intelligence.',
    //             'News chanel',
    //             'All rights reserved &copy; 2023-2024 TENETIX',
    //             'Yaroslaviv Val, 9',
    //             'Kyiv, Ukraine']);
    //     }else if(currentLanguage === 'UK'){
    //         setFooterText([
    //             'Будьте в курсі нових технологій ШІ', 
    //             'Підписуйтесь на наш телеграм-канал, щоб дізнаватися про останні розробки в галузі штучного інтелекту.',
    //             'Канал новин',
    //             'Всі права захищені &copy; 2023-2024 TENETIX',
    //             'Ярославів вал, 9',
    //             'Київ, Україна']);
    //     }
    // }, [currentLanguage]);

    const location = useLocation();
    const isNotGetInTouchPage = location.pathname !== "/get-in-touch";
    const newsChanel = {"url": "https://web.telegram.org/", "text": footerText[2], "margin": "5px 15px 5px 5px"}

    let footerStyle;
    if (isNotGetInTouchPage) {
        footerStyle = {}
    } else {
        footerStyle = {"minHeight": "0vh", "backgroundPosition": "bottom"}
    }

    // const fontStyles = `
    //     @font-face {
    //         font-family: 'CustomFont';
    //         src: url(${customFontWoff}) format('woff');
    //     }
    // `;

    return(
        <footer style={footerStyle}>
            <div className='main-container'>
                {   isNotGetInTouchPage &&
                    <div className='footer-subscribe'>
                        <h2 className='footer-subscribe_header'>{footerText[0]}</h2>
                        <p className='footer-subscribe_text'>{footerText[1]}</p>
                        <LinkWithBackgroundComponent data={newsChanel} />
                    </div>
                }
                <div className='footer-container'>
                    <div className='footer-image-container'>
                        {/* <style>{fontStyles}</style> */}
                        <svg width="250" height="100" xmlns="http://www.w3.org/2000/svg" style={{position: "absolute", bottom: "0"}}>
                            <rect width="100%" height="100%" fill="none" />
                            <text x="32%" y="50%" dominantBaseline="middle" fontFamily="'CustomFont'" fontWeight="bold" textAnchor="middle" fill="white" fontSize="36">
                                TENETIX
                            </text>
                        </svg>
                    </div>
                    <div className='footer-sections-container'>
                        
                        <div className=''>
                            <h5 className='footer-item_title'>SERVICES</h5>
                            {/* {
                                SERVICES.map((el) => (
                                    <Link key={uuid()} to={el.link}>{el.name}</Link>
                                ))
                            } */}
                        </div>
                        <div className=''>
                            <h5 className='footer-item_title'>COMPANY</h5>
                            {/* {
                                COMPANY.map((el) => (
                                    <Link key={uuid()} to={el.link}>{el.name}</Link>
                                ))
                            } */}
                        </div>
                        <div className=''>
                            <h5 className='footer-item_title'>CONTACT</h5>
                            <p>+38-063-776-6423</p>
                            <p>info@tenetix.ai</p>
                            <p>{footerText[4]}</p>
                            <p>{footerText[5]}</p>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className='footer-footer'>
                    <div>
                        <p dangerouslySetInnerHTML={{__html: footerText[3]}}></p>
                    </div>
                    <div className='footer_social-container'>
                        {SOCIAL_PAGES.map((el => <SocialItem key={el.name} data={el} />))}
                    </div>
                </div>
            </div>
        </footer>
    );
}