
import { Routes, Route } from "react-router-dom";
import { MainPageRoute, Layout } from "./index";
import { ScrollToAnchor } from "./scrollToAnchour";
import { ScrollToTop } from "./scrollToTop";

export default function AppRoutes(){

    return(
        <>
        <ScrollToAnchor />
        <ScrollToTop />
            <Routes>
                {
                    <Route path="/" element={<Layout />}>
                        {/* <Route path="/about-us#Careers" element={<AboutUsPage />}></Route> */}
                        {/* <Route path="/generative-AI#how-we-can-help" element={<AboutUsPage />}></Route> */}
                        <Route index element={<MainPageRoute />}></Route>

                        {/* <Route path="/article/:id" element={<BlogArticlePage />}></Route> */}


                        {/* <Route path="/ai-for-beginners" element={<CourseDesignPage />}></Route> */}
                        {/* <Route path="/ai-for-beginners" element={<PrivacyPolicyPage />}></Route> */}

                        {/* <Route path="/#about-anchor" element={<MainPage />}></Route> */}
                    </Route>
                }
            </Routes>
        </>
    );
}