import { HeroSection } from "./heroSection/HeroSection";
import { WhatWeDoSection } from "./whatWeDoSection/WhatWeDoSection";
import  {v4 as uuidv4} from "uuid";
import './main-page.css'
import { ContactSection } from "./contactSection/ContactSection";

export function MainPage(){

    const sections = [
        <HeroSection key='hero-section' />,
        <WhatWeDoSection key='what-we-do-section' />,
        <ContactSection key='contact-section' />
    ];

    return(
        <>
        {
            sections.map(section => (
                <section className="landing-page__section" key={uuidv4()}>
                    {section}
                </section>
            ))
        }
        </>
    );
}