

import { useEffect } from "react";
import image from '../../../../assets/bd.JPG'
import "./what-we-do-section.css"


function GetChessElement({data}){

    useEffect(() => {
        console.log(data);
    }, []);

    return(
        <>
            <div className="what-we-do__chess-element">
                {
                    data["type"] === "img"
                    ?
                    <img src={data["img-url"]} alt={data["alt-text"]} />
                    :
                    <></>
                }
            </div>
        </>
    )
}

export function WhatWeDoSection(){

    const chessItems = [
        {"type": "text",
        "title": "Навчання",
        "text": ""
        },
        {"type": "img",
        "img-url": image,
        "alt-text": "some image"
        },
        {"type": "text",
        "title": "Консультація та супровід",
        "text": ""
        },
        {"type": "img",
        "img-url": image,
        "alt-text": "some image"
        },
        {"type": "text",
        "title": "Пошук та розвиток",
        "text": ""
        },
        {"type": "img",
        "img-url": image,
        "alt-text": "some image"
        }
    ]

    return(
        <>
            <div className="what-we-do__container">
                <h2 className="what-we-do__title">ЯК МИ ДОПОМАГАЄМО</h2>
                <p className="what-we-do__main-description">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate deserunt accusamus temporibus provident libero facere quae, voluptatibus labore vel aliquid distinctio atque ducimus non aperiam, dolor sapiente fuga, molestias porro.
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate deserunt accusamus temporibus provident libero facere quae, voluptatibus labore vel aliquid distinctio atque ducimus non aperiam, dolor sapiente fuga, molestias porro.
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate deserunt accusamus temporibus provident libero facere quae, voluptatibus labore vel aliquid distinctio atque ducimus non aperiam, dolor sapiente fuga, molestias porro.
                </p>
                <div className="what-we-do__content">
                    {
                        chessItems.map(el =>(
                            <GetChessElement data={el}/>
                        ))
                    }
                </div>
            </div>
        </>
    );
}