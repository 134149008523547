
import {v4 as uuidv4} from "uuid";
import { BigNavButtonLink } from "../../../links/big-nav-button-link";
import img from "../../../../assets/ua.jpg"
import "./hero-section.css";

export function HeroSection(){

    const buttonsConfInfo = [
        {"button-url": "/", 
        "button-title": "Our Mission",
        "button-text": "Some other short description text"},
        {"button-url": "/", 
        "button-title": "Campaigns",
        "button-text": "Some other short description text"},
        {"button-url": "/", 
        "button-title": "Get Involved",
        "button-text": "Some other short description text"}
    ];

    return(
        <>
            <div className="hero__container">
                <img src={img} alt="yellow field end blue sky" />
                <h1>Допомога ветеранам і постраждалим від війни</h1>
                <h2>адаптуватися до цивільного життя, через перекваліфікацію у нові професії за допомогою новітніх технологій, ШІ та інклюзивних підходів</h2>
                <div className="nav-buttons__container">
                    {
                        buttonsConfInfo.map(buttonEl => (
                            <BigNavButtonLink data={buttonEl} key={uuidv4()} />
                        ))
                    }
                </div>
            </div>
        </>
    );
}